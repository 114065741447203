.container {
  min-width: 16px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-size: cover;
  border-radius: 2px;
}

.disabled {
  cursor: default;
}

.clear {
  background-image: url(/assets/images/icons/checkbox_clear.png);
}

.checked {
  background-image: url(/assets/images/icons/checkbox_checked.png);
}

.checkedWhite {
  background-image: url(/assets/images/icons/checkbox_checked_white.png);
}

.indeterminate {
  background-image: url(/assets/images/icons/checkbox_indeterminate.png);
}

.indeterminateWhite {
  background-image: url(/assets/images/icons/checkbox_indeterminate_white.png);
}
