@import "styles/scss/main.scss";

.tabContent {
  &:global(.react-tabs__tab-panel--selected) {
    min-height: 290px;
  }
}

.weatherTable {
  :global(.row) {
    padding: 3px 5px;
  }
}

.cropTable {
  label {
    text-align: center;
  }
  :global(.row) {
    padding: 3px 5px;
  }
}

.cropTableBody {
  max-height: 140px;
  overflow-y: auto;
  overflow-x: hidden;
}

.errorRow {
  background: #fec8b7;
}

.error {
  font-family: $primary-font-family;
  font-size: 12px;
  color: $error-color;
}

.fixedDateTime {
  :global(.rdtPicker) {
    position: fixed;
  }
}

.delete {
  margin-left: 5px;
  cursor:pointer;
  &:hover {
    color: #0d5aa7;
  }
}

.addSeasonBtn {
  min-width: auto !important;
  margin-left: 5px;
}
