@import 'styles/scss/main.scss';

.sidebar {
  background-color: $primary-background-color;
  color: $sidebar-color;
  min-width: 260px;
  max-width: 260px;
  display: flex;
  flex-direction: column;
}
