@mixin custom-sort-icons {
  :global(.ReactTable .rt-thead .rt-th.-sort-asc),
  :global(.ReactTable .rt-thead .rt-th.-sort-desc) {
    box-shadow: none;
  }

  :global(.ReactTable .rt-thead .rt-resizable-header-content) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  :global(.ReactTable .rt-thead .rt-resizable-header-content):first-child {
    flex: 1;
  }

  :global(.ReactTable .rt-thead .rt-resizable-header-content)::after {
    content: '';
    width: 8px;
    height: 8px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  :global(.ReactTable .rt-th.-sort-asc .rt-resizable-header-content)::after {
    background-image: url(/assets/images/icons/sort_asc.svg);
  }

  :global(.ReactTable .rt-th.-sort-desc .rt-resizable-header-content)::after {
    background-image: url(/assets/images/icons/sort_desc.svg);
  }
}

@mixin custom-header-height {
  :global(.ReactTable .rt-thead .rt-resizable-header-content) {
    min-height: 45px;
  }
}

@mixin custom-header-background {
  :global(.ReactTable .rt-thead.-headerGroups) {
    background: none;
  }

  :global(.ReactTable .rt-thead.-header) {
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  :global(.ReactTable .rt-thead .rt-resizable-header-content) {
    display: flex;
    white-space: normal;
    text-align: left;
    min-height: 45px;
    justify-content: space-between;
    align-items: center;
  }
}

:global(.ReactTable .rt-tbody) {
  overflow: overlay !important;
}
