.button {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  margin: 2px;
  padding: 2px;
}

.icon {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.button:hover .icon {
  opacity: 0.8;
}

.button:active .icon {
  opacity: 0.6;
}

.button:disabled {
  cursor: default;
}

.button:disabled .icon {
  opacity: 0.5;
}

.item_add {
  background-image: url(/assets/images/icons/button_add.png);
}

.item_edit {
  background-image: url(/assets/images/icons/button_edit.png);
}

.folder_add {
  background-image: url(/assets/images/icons/folder_add.png);
}

.folder_edit {
  background-image: url(/assets/images/icons/folder_edit.png);
}

.folder_delete {
  background-image: url(/assets/images/icons/folder_delete.png);
}

.vehicle_attach {
  background-image: url(/assets/images/icons/vehicle_attach.png);
}

.vehicle_detach {
  background-image: url(/assets/images/icons/vehicle_detach.png);
}

.chevron_left {
  background-image: url(/assets/images/icons/chevron_left_mini.svg);
}

.chevron_double_left {
  background-image: url(/assets/images/icons/chevron_double_left_mini.svg);
}

.chevron_right {
  background-image: url(/assets/images/icons/chevron_right_mini.svg);
}

.chevron_double_right {
  background-image: url(/assets/images/icons/chevron_double_right_mini.svg);
}