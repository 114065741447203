$value-color: red;

.container {
  display: flex;
  flex-direction: column;
  width: 30rem;
  border: 1px solid $value-color;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin: 0.5rem;
}

.centered {
  display: flex;
  justify-content: center;
}
.value {
  font-size: 2rem;
  color: $value-color;
}
.input {
  width: 4rem;
  margin: 0.25rem;
}
.buttons {
  display: flex;
  justify-content: space-around;
}
