@import 'styles/scss/main.scss';
@import 'styles/scss/tables.scss';

.wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.table {
  font-family: $primary-font-family;
  font-size: $primary-font-size;
  background: white;
  width: 100%;
  height: 50%;
}

.cell_header {
  white-space: normal;
}

.cell_number {
  text-align: right;
}

.map,
.mapLoading {
  width: 100%;
  height: 50%;
}

.mapLoading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  @include custom-sort-icons;
  @include custom-header-height;
  @include custom-header-background;
}

.nullValuePopup {
  text-align: center;
  cursor: pointer;
}
