.container {
  padding: 10px;
}

.buttonsContainer {
  padding-top: 10px;
  display: flex;
  align-items: center;
}

.checkbox {
  margin-right: 2px;
}
