@import 'styles/scss/main.scss';

.container {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.selected {
  text-shadow: 2px 2px 5px;
}

.folder {
  width: 16px;
  height: 16px;
  background-size: cover;
}

.folderOpen {
  background-image: url(/assets/images/icons/folder_open.png);
}

.folderClosed {
  background-image: url(/assets/images/icons/folder_closed.png);
}

.leafPlot {
  background-image: url(/assets/images/icons/leaf_plot.png);
}

.leafField {
  background-image: url(/assets/images/icons/leaf_field.png);
}

.leftMargin {
  margin-left: 5px;
}

.caption {
  font-family: $primary-font-family;
  font-size: 13px;
  color: $primary-color;
}
