@import 'styles/scss/main.scss';

.container {
  width: 15rem;
  padding: 0.25rem;
  font-family: $primary-font-family;
  color: $primary-color;
  font-size: $primary-font-size;
}

.header {
  // font-size: 1rem;
  font-weight: bold;
}

.coordinates {
  color: #808080;
}

.label {
  font-weight: bold;
  margin-top: 0.5rem;
}

.value {
}

.twoColumns {
  display: flex;
}

.twoColumns > div {
  width: 50%;
}
