.container {
  padding: 10px;
}

.buttonsContainer {
  padding-top: 10px;
  display: flex;
  align-items: center;
}

.checkbox {
  margin-right: 2px;
}

.activityFilters {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
