@import 'styles/scss/main.scss';

.loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -12px;
  text-align: center;
}

.wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
}

h1 {
  font-family: $primary-font-family;
  font-size: 18px;
  line-height: 35px;
}

.header {
  height: 35px;
  width: 100%;
  h1 {
    margin: 0 15px;
  }
}

.timelineContainerWrapper {
  flex-grow: 1;
  width: 100%;
  position: relative;
  font-family: $primary-font-family;
  color: #224265;
}

.timelineContainer {
  width: 100%;
  height: 100%;
  position: absolute;
}

.sidebarHeader {
  text-align: center;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.siderbarPlannerType {
  margin: 0 10px;
  margin-top: 5px;
  width: 230px !important;
  font-size: 14px !important;
}

.intervalSelector {
  display: none;
  z-index: 100;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  .selected {
    position: absolute;
    right: auto;
    background: rgba(255, 0, 0, 0.2);
  }
}

.intervalSelectorActive {
  display: block !important;
}

.iconDelete {
  position:absolute;
  display: block;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: url(/assets/images/icons/close.svg) #f5812f center no-repeat;
  background-size: contain;
  box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.75);
  &:hover {
    background-color: #999;
    color: #fff;
  }
}

.rctWrapper {
  position:relative;
  display: inline-block;
  max-width: 100%;
  height: 100%;
}

.iconDeleteLeft {
  top: -3px;
  right: -30px;
}

.iconDeleteTop {
  top: -30px;
  right: -5px;
}

.toolbar {
  margin-top: 5px;
  margin-right: 15px;
  float: right;
  > div {
    display: inline-block;
    vertical-align: middle;
  }
}

.iconButton {
  cursor:pointer;
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 4px;
  border: 1px solid transparent;
  border-radius: 2px;
  &:hover {
    opacity: 0.6;
    border-color: #666;
  }
}

.smallIcon {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 4px;
}

.plus {
  background-image: url(/assets/images/icons/plus.png);
}

.minus {
  background-image: url(/assets/images/icons/minus.png);
}

.zoomIn {
  background-image: url(/assets/images/icons/zoom-in.svg);
}

.zoomOut {
  background-image: url(/assets/images/icons/zoom-out.svg);
}

.scrollLeft {
  background-image: url(/assets/images/icons/chevron-left.svg);
}

.scrollRight {
  background-image: url(/assets/images/icons/chevron-right.svg);
}

.sep {
  margin: 0 5px;
  width: 1px;
  height: 18px;
  border-left: 1px solid #666;
}


.toggleAllCont {
  width: 100%;
  text-align: left;
}

.toggleAll {
  margin-left: 5px;
  margin-bottom: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
}
