@import 'styles/scss/main.scss';

.icon_container {
  opacity: .8;
  vertical-align: middle;
  isplay: inline-block;
  height: 16px;
  font-family: $primary-font-family;
}
li .icon_container {
  margin-right: 10px;
}

.icon {
    display: inline-block;
    background-size: 16px auto;
    width: 16px;
    height: 16px;
}

.icon_admin {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsSAAALEgHS3X78AAABi0lEQVRYhe2X33GCQBCHPzK+YwcxFYQOnHRgOiAVhBJMBaEE00FKwA4oATuACtYHFlkBw78j5iG/mRtv8M79uN3bXRGRVIYrF5GdiOBqeCIijFMBBEA2cl+nHszc6xlPatwHvl0YbwL0KQNCnT8DsRMC49+hfovNntnxMAUAE7i5iGzmAIxxgdUOR/Fgb8HLBIh3nX8Bh4H7ciDtAvhNvaInN9UFcxVUkzF5wMX4aJLc6wT+Af4OwNRMOHWsReSgn61y7N3jAJZ2QQgIdRVtackTWFOWcB84USaffGmA6reOwAZ4NN+dFGhr7S0F0DTahKntOb4FiekTms3KTp+LrpvVkNy6YpWBW51SZADXLgFCYzzrWZsZiNAVgFXSszaxi1cmKPYjgi3m+kq96TNfA+4nVb1AAURN+qHqestJMbBS+nDE2wNEHc+qXm9L2R/uuf7vEAGfOk91PZ6I05bwVh4IKN1jdUlESwAcO4wW1Cd0AbBB6EI2m9paUFAGZ6sWLFkNc+pY2XcZBzgDbXH82iYMZ7oAAAAASUVORK5CYII=);
}

.icon_extend {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAAEEfUpiAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAWJJREFUeNpi+P//f8N/JMAAJqAAQwAEmBjQAEAAMaKrYEDSP5+BKAAQQAzYzAAKOYAwjIPiUJgGIPUepwl43Y0OAAKIkZAJDPgciC8QYA79z4JNFSMQEBUuIDZBX1CuACCAGEgOBzS9TEQqfI8el2TZhi2UYOHsSED/fljYY8QBMU7CpYboMKAsmggAFlKiEpqLBJDFAAKIkZJ0AASNFCckFmIzIRL3AjAmPxAdjUDpALRCZT/e0g+fc6FJGsUARiI1rwdSIGc/BGJ7oBcc4QnpPxEAqPYiUFMiyP9AfBBbNBKdFzCS+NDPC6MGkF4eYKgDCDBKywMGqviAYI1JAwDzOBOVDCsgkJ3XEypm/lPZd/NxlYHo9rKAy3XqWdwPpAqg3AXQ4q+egc5xa4DEbiAUAlTPBcQmZpi9VMsF5HqEiWGAwagDRh0w4A5goZXBwFwJaoYbEKOw4T/1QAOunhku9QCx4yYosMxVNAAAAABJRU5ErkJggg==);
}

.icon_exit {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAAEEfUpiAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAARVJREFUeNpiYACC/1DAgA0wwhg4VQAEECOyDCMQYChlQTPjAYYKgADCbwMQMOGSgGnCqQAIPhBlBUAAMWJTAPYPbQHQxgAgNsAmYfAfCeAzYT/13AMQQEQFFj7ARKnFTCS60gA9+EgyABjDF4AULKgNyAoDqOb3gycQGUlxAbZkDhBAMM0N/wmDfqonIvonpEGRlBsoCkSqpEJ0Q+DlIrZEgrdARVZPghcSqJETDUZTIg4DyM3OAAGGLZSpAfqJ9QELSSUXDQATwwCDUQfQzAGgNhSs5MNafODLBVR0RAJSziDsEFIcgN6cJAHcp1sIkBwK1HQAtCX+n6R0QGUHGEBbXAakaKJ6FIwWREPOAY1UqpaJro4B/vtGkkh2bpYAAAAASUVORK5CYII=);
}

.icon_user {
    background: url(/assets/images/images.png) no-repeat 0 0;
}

.icon_calendar {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAAEEfUpiAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAfdJREFUeNpiYAABjZD3DFDACOT8Z0ACTAxoAFXgxhpGgABCV4BpBgPUlv/YJYAAIIBAWhqA9AEg3o9hqEbIega4VphjkRyN7iwBOI1iH5oOdAAQQNgRkiYmHM4XgHmPEaf/iLICbAIspDDBBySf4QhWII0RX5hRBA5OnG75DxBAMEYDsrEoVqFZiST3HtlNBgzkALgfbqy5wEAmYMLtLyx8LMHNQqxTcUmxYFWIL3lA5D4AvS1I2MmEvEAgKREDLgAEEN68QIwcC5KEADQZCmB1FaZcI1CsgQXK2QDkBBIsdhDAEVyKaIRQ4H+NEAcKcjSRiYT4vIArpNFDHUssMOEssTDZ2PjQ4hwU4rCoJAbAYgjFC6C0DZMA0biiEU2ciYFCwIIWQAJE5UYUV5CY+whHI90SEqi4B3qbBZynyTUEGBYAAUacC8mxgEh9sPLkPJA0IMp1hAwlXt8FoFpDFqTkg1kmIWppEoONgD5IdR0AshtRHBFvMHUA1F4qlIfUyskDFAJMFKVmcuWoWRhSCrAnQlLqBuIBUnMIEQUsOPIyhkKsaYSYBEwgbQ14FLDgaYIJUKnS+I81CvA6gNQoIFUOaxSAWmq4i9QPVJVD6tPAeqz1UP4GaKP1Ao0KH5An88H1AAQ0Ime9BqikAI3THShUJoJa5iAOAHej+47Nll3jAAAAAElFTkSuQmCC);
}

.icon_help {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAAEEfUpiAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAphJREFUeNpiYICC/0DAgBXAZIDUexDNxAgEULkNIAIggGCq9gNxA4xTgN9gmGEwFQABBGMkIGtFUQiyAIuT4GYzAfFBGAfmRCSnYnoInQ8QQMgCBv+hgKBudB/gCEWI70DEfCQBBSC+j2IKyG5c1sEU3MdjxX9QODxA9gkyGx4eoGAF4gC06APzAQII3UiF/wgwH4gdoBgUV/eh4vvxaRRgIAIA1Z1HjmwDHAF9/j8qcECTD4CFEoYkttDC5nSQIAuUrYBDI8jg/VhTF6G0AhXvxyMHionz6M48T0QAwlJtAM4E+B83wLAAIIBwGdKApnE/epplwOOv/9BEk4CUkOYjGaaAL6reE7CgAKouAaOsIdZ/oNSK4hJYoDGQAGAuQXZ6AA5b/uMqaKDCBrgKnP0YJQOmmvng5E3I+dBY+I9LnIlQqgPlBSBOJDkfIPlzPr4UywgmCOU27AaACsuFsIJjPx4XFBB0OTxKMBWtx1bMQRPefoziiZgMAytcsUkE4Cx1UeXfE1Pi4gIJ6OoBArRetUUIwzB0u0PIJNQBw8EkgINKwAESQAIONgfFwXBQHIz2LtnlQrsmK/0DP5rm872XSap9LjyaJZ9dDkm6nhHSoLQfib0X2ZM6YwamqTzA4bSCQ4lN/T8cZwLxyUEjEY5CQr2zFmGbeoVsOC5As1KsHLSsB8AuCmnFRefaVEEkAz8hshZOx++a3oa/ODcngdnq61CZdXSMXHkJpP7Y88i4pRslpZNshxk5XVBSTRGjeTRY7ewwrjGpfc4qs4jnpllncstVR7DtpOu+olozIbpOBBG4bCscW0ZYP+1qN4xjlHEwewafCb5REUofQEK8f4T7NMMJEJKEeavBe/gZiJPUweCeweFL8u4XC/2w4NFUR2IAAAAASUVORK5CYII=);
}