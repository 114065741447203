@import 'styles/scss/main.scss';
@import 'styles/scss/icons.module.scss';

.menu {
  background-color: $primary-background-color;
  color: $sidebar-color;
  width: 100%;
}

.logo_container {
  background-image: url(./image/logo.png);
  height: 72px;
  background-size: cover;
  background-size: 150px auto;
  background-position: 10px 21px;
  background-repeat: no-repeat;
  vertical-align: middle;
  cursor: pointer;

  background-color: $contrast-background;
  color: $logo-color;
  width: 100%;

  transition: opacity 0.3s;
}
.logo_container:hover {
  opacity: $standard-hover-opacity;
}


.dots {
  background-color: $contrast-background;

  width: 16px;
  height: 16px;
  display: inline-block;
  float: right;
  margin-top: 28px;
  margin-right: 5px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAFo9M/3AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAGFJREFUeNpiYACC////n2digAKAAMJEAAFEGAEEEAPMFApUAAQQhkp01ZS7EN0KMEAWAwggDAXoYjRwA42tIGS/AjY3AAQYIzEOZgQChqEFYP4lGPeD3hvnoT7Am58oTkoA1y5IttlrFFkAAAAASUVORK5CYII=);
}

.list {
  background-color: $contrast-background;
  font-size: 13px;
  line-height: 16px;
  width: 100%;

  padding: 0 0 8px;
  list-style: none;
  margin: 0;
  padding-inline-start: 0;

  position: absolute;
  max-width: 260px;
  z-index: 100;
}

.list li.hoverable {
  height: 30px;
  cursor: pointer;
  list-style: none;
  color: #d1d8e0;
  padding: 0;
}

.list li div {
  color: inherit;
  display: flex;
  padding: 7px 10px;
  text-decoration: none;
  line-height: 15px;
}


.icon_user {
    width: 28px;
    height: 28px;
}

.user {
    padding: 30px 10px 12px;
}

.user .icon_container {
  position: relative;
  top: -9px;
  opacity: .8;
  margin-right: 10px;
  vertical-align: middle;
  display: inline-block;
}

.user_name {
  color: #ffffff;
  line-height: 16px;
  font-size: 14px;
  display: inline-block;
  max-width: 200px;
}


.separator {
    font-size: 12px;
    cursor: default;
    color: inherit;
    padding: 0!important;
    margin: 8px 10px;
    display: block;
    height: 1px;
    background-color: #1a3e65;
    list-style: none;
}