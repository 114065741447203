@import 'styles/scss/main.scss';

.container {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  flex-grow: 1;
}

.innerContainer {
  display: flex;
  align-items: center;
  position: sticky;
  right: 0;
}

.group {
  display: flex;
  align-items: center;

  border: 1px solid #b9c4c7;
  border-radius: 3px;
  height: 24px;
  padding: 0 5px;
  margin: 6px 6px 6px 0;
}
.group,
.group select {
  color: $primary-color;
}

button.smallButton {
  padding: calc(2rem / 16) calc(10rem / 16) !important;
  min-width: 0 !important;
  margin-right: 6px;
}

// .container :global(.ReactTable) :global(.-pagination) select,
.container select {
  padding: 0 !important;
  border: none !important;
}

.popupContent > div {
  display: flex;
  align-items: center;
  margin: 6px 0;
  user-select: none;
}

.popupContent > div > div:last-child {
  margin-left: 6px;
  cursor: pointer;
}
