.react-calendar-timeline {
  width: 100%;
  height: 100%;
  overflow:auto;
  .rct-outer,.rct-dateHeader {
    background: #fff;
  }
  .rct-dateHeader {
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
  .rct-dateHeader-primary {
    color: #224265;
    border-bottom: 1px solid #e5e5e5;
  }
  .rct-header-root {
    background-color: #fff !important;
    position: sticky;
    top: 0;
    z-index:222;
    border-bottom: none;
  }

  .rct-calendar-header {
    border-color: #e5e5e5;
  }

  .rct-vertical-lines .rct-vl.rct-vl-first {
    border-left-width: 1px;

  }

  .rct-sidebar {
    border-right: none;
  }

  .rct-scroll {
    border-left: 1px solid #e5e5e5;
  }

  .rct-sidebar .rct-sidebar-row,.rct-horizontal-lines .rct-hl-odd, .rct-horizontal-lines .rct-hl-even {
    border-bottom-color: #e5e5e5;
  }

  .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd, .rct-horizontal-lines .rct-hl-odd {
    background: #f5f5f5;
  }

  .rct-vertical-lines .rct-vl {
    border-left-color: #e5e5e5;
  }

  .rct-vertical-lines .rct-vl.rct-day-6, .rct-vertical-lines .rct-vl.rct-day-0 {
    background: transparent !important;
  }

  .rct-dateHeader.weekend {
    background: #ffccc6;
    color: #ca240e;
    border-right: 1px solid #ffccc6;
    border-bottom: 1px solid #ffccc6;
  }

  .rct-item {
    .left-arrow, .right-arrow {
      content: '';
      position: absolute;
      top: 0;
      width: 3px;
      height: 100%;
    }
    .left-arrow {
      left: -3px;
      cursor: w-resize;
    }
    .right-arrow {
      right: -3px;
      cursor: e-resize;
    }
    .rct-item-content {
      max-width: 100%;
      white-space: nowrap;

    }
  }
}
