.tabsList {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.tab {
  display: block;
  list-style-type: none;
  width: 100%;
  border: 1px solid transparent;
  border-bottom: 1px solid #c6c7c4;
}

.tabSelected {
  background-color: #fff;
  border: 1px solid;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: currentcolor;
  border-bottom: 0;
}

.tabSelected:first-child {
  border-left: 0;
}

.tabSelected:last-child {
  border-right: 0;
}