.container {
  margin: 10px 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.counter {
  font-size: 10px;
}
