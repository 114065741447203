@import 'styles/scss/main.scss';

.container {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.selected {
  text-shadow: 2px 2px 5px;
}

.leftMargin {
  margin-left: 5px;
}

.caption {
  font-family: $primary-font-family;
  font-size: 13px;
  color: $primary-color;
}
