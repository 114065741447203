@import 'styles/scss/main.scss';

.wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inner {
}
