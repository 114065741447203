.wrapper {
  height: 100%;
  display: flex;
}

.border {
  border-right: #8a9ca2 solid 1px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.wrapper_page {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow:hidden;
}

.wrapper_right {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #ececec;
}

.periods {
  min-height: 30px;
  padding: 0 10px;
}

.tabs {
  display: flex;
  flex-direction: column;
}
ul.tabs_list {
  min-height: 50px;
}
.panel {
  display: flex;
  flex-direction: column;
}

:global(.leftPanelList ul) {
  overflow-y: auto;
}
