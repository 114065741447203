@import 'styles/scss/main.scss';

.calendar_dates {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: $primary-color;
  margin: 10px;
  font-family: $primary-font-family;
}
.calendar_dates:hover {
  opacity: $standard-hover-opacity;
}

.datetime {
  font-size: 20px;
}
.date {
  font-size: 20px;
  line-height: 1.2em;
}
.time {
  font-size: 15px;
  line-height: 1em;
}

.separator {
}

