.period_selector_title {
  display: inline-block;
  font-size: 12px;
}

.period_label {
  display: inline-block;
  margin: 0;
  padding: 0 6px;
}

.period_input {
  display: inline-block;
  background-color: #1a3d65;
  margin: 0 5px;
  padding: 3px 14px;
  line-height: 18px;
}

.ic_defice {
  display: inline-block;
  color: #4e6288;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.content>div>p {
  margin-bottom: 0;
  font-size: 12px;
}

input[type="text"].minutes {
  margin: 0 10px;
  width: auto;
}

.select_light_wrap {
  display: inline-block;
}

.select_light {
  border: none;
  border-bottom: 1px solid;
  background: none;
  outline-style: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
