.modal {
  max-width: 600px;
}

.container {
  max-height: 400px;
  overflow-y: auto;
}

.container table {
  margin-bottom: 0;
}

.rowContainer {
  margin: 0;
}

.tableContainer {
  height: 280px;
  overflow-y: auto;
}

.tableBody td:first-child {
  width: 1rem;
}

.tableBody tr {
  cursor: pointer;
}

.tableBody tr.selected {
  cursor: default;
  font-weight: bold;
}

.tableBody td > * {
  vertical-align : middle;
}