@import 'styles/scss/main.scss';

.sidebarItem {
  padding-bottom: 6px;
  cursor: pointer;
  color: #4e6288;
  text-align: center;
  width: 100%;
}

.iconContainer {
  display: flex;
  justify-content: center;
  height: 24px;
  transition: opacity 0.3s;
  opacity: 0.6;
  margin-top: 7px;
}
.sidebarItem:hover .iconContainer {
  opacity: 1;
}

.icon {
  width: 24px;
  height: 24px;
  background-size: 24px auto;
}

.vehicles {
  background-image: url(/assets/images/icons/vehicles.svg);
  margin-top: 1px;
}

.trailer {
  background-image: url(/assets/images/icons/trailer.svg);
}

.territory {
  background-image: url(/assets/images/icons/territory.svg);
}

.work_type {
  background-image: url(/assets/images/icons/work_type.svg);
}

.label {
  font-family: $primary-font-family;
  font-size: 12px;
}
