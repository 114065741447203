.backdrop {
  opacity: 0.2 !important;
}

.content {
  border-radius: 0;
  border: #c6c7c4 1px solid;
  font-size: 13px;
}

.header {
  border-radius: 0;
  color: #ffffff;
  background-color: #002854;
  line-height: 18px;
  min-height: 40px;
  padding: 13px;

  & :global(button.close) {
    opacity: 0.8;
    font-size: 20px;

  }
  & :global(button:focus) {
    outline:0;
  }
  & :global(button.close span) {
    text-shadow: none;
    color: #ffffff;
    font-weight: normal;
  }

  & :global(h5.modal-title) {
    font-size: 13px;
  }
}

.footer div > * {
  margin-left: 10px;
}
