.container {
  padding: 10px 0;
  margin: 0;
}

.left > * {
  margin-left: 0;
  margin-right: 10px;
}

.right {
  text-align: right;
}
.right > * {
  margin-left: 10px;
  margin-right: 0;
}

.center {
  text-align: center;
}
.center > * {
  margin-left: 5px;
  margin-right: 5px;
}

.hr {
  margin: 0;
}
