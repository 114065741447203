@import "fonts/index.scss";

// Основной цвет текста
$primary-color: #002854;

// Основной фон, используется в сайдбаре
$primary-background-color: #ffffff;

// Основной цвет текста, используется в сайдбаре
$sidebar-color: #4e6288;

// Основной контрастный фон, используется в главном меню
$contrast-background: #002854;

$error-color: #fc4f16;

$logo-color: #ffffff;

$primary-font-family: "Roboto Condensed", Helvetica, sans-serif;

$standard-hover-opacity: 0.8;

$primary-font-size: 13px;
