@import 'styles/scss/main.scss';

.popper {
  background: $primary-background-color;
  color: $primary-color;
  font-family: $primary-font-family;
  font-size: $primary-font-size;
  border-radius: 0.25rem;
  box-shadow: 0 0.0625rem 0.3125rem rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  text-shadow: 0;
  z-index: 500;
}

.popper:empty {
  display: none;
}
