@import 'styles/scss/main.scss';
@import 'styles/scss/tables.scss';

.wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.table {
  width: 100%;
  height: 100%;
  font-family: $primary-font-family;
  font-size: $primary-font-size;
  background: white;

  :global(.rt-tr-group) {
    cursor: pointer;
    flex-grow: 0 !important;
    position: relative;
    &:hover {
      .deleteBtn {
        display: block !important;
      }
    }
  }
}

.wrapper {
  @include custom-sort-icons;
  @include custom-header-height;
  @include custom-header-background;
}

.deleteBtn {
  display: none !important;
  position: absolute !important;
  right: 0;
  top: 50%;
  margin-top: -15px;
  margin-right: 10px;
}

.fixedDateTime {
  :global(.rdtPicker) {
    position: fixed;
  }
}

.error {
  font-family: $primary-font-family;
  font-size: 12px;
  color: $error-color;
}
