.offsetValue {
  width: 100px !important;
}

.offsetContainer {
  margin-bottom: 20px;
}

.offsetError {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  margin-top: 32px;
}
