@import "styles/scss/main.scss";

$button-text: #fff;
$button-bg: #032853;
$button-bg-hover: #00517a;
$button-bg-disabled: #99a9ba;
$button-border: #032853;
$button-border-hover: #00517a;

button.button {
  text-align: center;
  display: inline-block;
  font-weight: 400;
  cursor: pointer;
  line-height: 18px;
  border-radius: 2px;
  padding: 4px 10px;
  position: relative;
  min-width: 80px;
  color: #fff;
  font-family: $primary-font-family;
  font-size: 12px;
  background: $button-bg;
  border: 1px solid $button-border;
  user-select: none;
}

button.button:hover {
  background: $button-bg-hover;
  border-color: $button-border-hover;
}

button.button:active {
  background: $button-bg;
  border-color: $button-border;
}

button.button-disabled {
  background: $button-bg-disabled !important;
  border-color: $button-bg-disabled !important;
  cursor: default;
}

:global(button.btn-warning) {
  background-color: #fb4f14;
  border: 1px solid #fb4f14;
}

:global(button.btn-warning):hover {
  color: #fff;
  background-color: #fc582b;
  border: 1px solid #fc582b;
}

:global(.btn).focus,
:global(.btn):focus {
  box-shadow: none !important;
}

:global(button.btn-warning):not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #ea3606 !important;
  border-color: #ea3606 !important;
}
