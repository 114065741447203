@import "styles/scss/main.scss";

.container {
  position: relative;
}

select.input, [type="text"].input, [type="number"].input {
  height: 30px;
  border-radius: 3px;
  font-family: $primary-font-family;
  font-size: 13px;
  background-color: #fff;
  resize: none;
  padding: 5px 10px 5px 10px;
  width: 100%;
  outline: 0;
  box-shadow: inset 0 1px 1px transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

select.input,
select.input:focus,
[type="text"].input,
[type="numer"].input,
[type="text"].input:focus,
[type="numer"].input:focus {
  color: #333;
  border: 1px solid #c6c7c4;
}

.input::placeholder,
.input::-ms-input-placeholder {
  color: #c6c7c4;
}
.input:focus {
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px #66afe9;
}

.button {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 16px;
  margin-top: -9px;
  width: 16px;
  height: 16px;
  border: none;
  background-color: transparent;
  outline: none;
  opacity: 0.8;
}

.button[rel='search'] {
  background-image: url(/assets/images/icons/search.png);
  background-size: 16px auto !important;
}

.inline {
  display: inline-block;
  width: auto;
}

.centerText {
  text-align: center;
}

.error {
  font-family: $primary-font-family;
  font-size: 12px;
  color: $error-color;
}
