@import 'styles/scss/main.scss';

.menu {
  display: flex;
  align-items: center;
  background-color: #03274F;
  color: #B3BECA;
  height: 36px;
  > div {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  > div > ul {
    display: none;
  }
  > div:hover > ul {
    display: block;
  }
}

.menu a {
  display: block;
  margin: 0;
  color: #B3BECA;
  font-size: 14px;
  font-family: $primary-font-family;
  white-space: nowrap;
  padding: 7px 12px;
}

.menu {
  color: #B3BECA;
}

.menu a:hover {
  color: #ffffff;
  background: #FB4F14;
  text-decoration: none;
}

.menu a.selected {
  display: block;
  color: #ffffff;
  background: #FB4F14;
  font-weight: normal;
}

.submenu {
  top:35px;
  left: -8px;
  position: absolute;
  z-index: 2000;
  background: #03274F;
  padding-left: 0;
  margin-left: 7px;

  li {
    list-style: none;
  }
}
