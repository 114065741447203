
.list {
  padding: 0;
  margin: 0;
  list-style: none;

  min-height: 0; /* without min-height/height:0 flex:1 doesn't work */
  flex: 1;
  overflow: auto;
}

.listItem {
  list-style: none;
}
