$value-color: red;

.container {
  display: flex;
  width: 30rem;
  border: 1px solid $value-color;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin: 0.5rem;
}

.value {
  width: 100%;
  margin: 0 1rem;
  border: 1px solid $value-color;
}
