.mapContainer {
  width: 100%;
  height: 100%;
}

.mapContainer :global(.leaflet-container) {
  height: 100%;
}

.mapContainer .titleContainer {
  position: absolute;
  text-align: center;
  left: 50%;
  max-width: 80px;
  min-width: 200px;
  display: block;
  margin-top: 5px;
}

.mapContainer .titleContainer > div {
  position: relative;
  background: white;
  border: 1px solid #e8e8e8;
  right: 50%;
  display: inline-block;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px;
  color: #333;
}

.polygonTooltip {
  // background: none;
  // border: none;
  box-shadow: none;
}