@import "styles/scss/main.scss";

.datetime {
  color: $primary-color;
  font-family: $primary-font-family;
  font-size: $primary-font-size;
  margin-bottom: 0;
}

.datetime input{
  color: $primary-color;
  font-family: $primary-font-family;
  font-size: $primary-font-size;
  height: auto;
  padding: 5px 10px;
}

.error {
  font-family: $primary-font-family;
  font-size: 12px;
  color: $error-color;
}
